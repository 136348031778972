<template>
  <div class="full">
    <van-nav-bar title="商品详情" @click-left="back()">
      <template #left>
        <van-icon color="#fff" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <!-- 轮播 -->
    <div class="top">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="height: 5rem;">
        <van-swipe-item v-for="(item, index) in goodsInfo.attachments" :key="index">
          <van-image :src="item.url" class="recr_swiper_item_img" fit="cover">
            <template v-slot:error>加载失败</template>
          </van-image>
        </van-swipe-item>
      </van-swipe>
      <!-- 商品信息 -->
      <div class="info">
        <h4>{{goodsInfo.name}}</h4>
        <p v-if="goodsInfo.postCouponPrice">
          <span><sub>原价 <em>￥</em></sub>{{goodsInfo.sellPrice?goodsInfo.sellPrice/100:0}}</span>
          <span class="red"><sub>券后价 <em>￥</em></sub>{{goodsInfo.postCouponPrice?goodsInfo.postCouponPrice/100:0}}</span>
        </p>
        <p v-else><span><sub> <em>￥</em></sub>{{goodsInfo.sellPrice?goodsInfo.sellPrice/100:0}}</span></p>
      </div>
    </div>
    <!-- 中间详情 -->
    <div class="goods">
      <p class="tttop">
        <span></span>
        <span>商品详情</span>
        <span></span>
      </p>
      <template v-if="goodsInfo">
        <div class="goods_detile" id="picture" v-html="goodsInfo.depiction"></div>
      </template>
      <van-empty v-else description="暂无内容~" />
    </div>
    <div class="btotp"></div>
    <!-- 确认按钮 -->
    <van-button class="btnbb" color="#3FD467" @click="openInfoPop()">立即购买</van-button>
    <!-- 弹窗商品详情 -->
    <van-action-sheet v-model:show="goodsShow" class="infoPop">
      <div class="content">
        <img class="goods_img" :src="goodsInfo.cover" alt=""/>
        <div class="goods_info">
          <h2><sub>原价￥</sub>{{goodsInfo.sellPrice?goodsInfo.sellPrice/100:0}}</h2>
          <p>库存 {{goodsInfo.commodityStock.availableStock}} 件</p>
        </div>

      </div>
      <div class="buy">
        <span>购买数量</span>
        <van-stepper v-model="buyInfo.num" integer />
      </div>
      <van-button class="btn" color="#3FD467" @click="goBuy()">确定</van-button>
    </van-action-sheet>
  </div>
</template>
<script src='./goodsInfo.js'></script>
<style lang="scss" scoped src='./goodsInfo.scss'></style>
