import { SwipeItem, Swipe, Image, Button, ActionSheet, Tag, Stepper, NavBar, Icon,Empty,Toast } from 'vant';
import apis from '../utils/apis'
export default {
  name: 'goodsInfo',
  components: {
    'van-swipe-item': SwipeItem,
    'van-swipe': Swipe,
    'van-image': Image,
    'van-button': Button,
    'van-action-sheet': ActionSheet,
    'van-tag': Tag,
    'van-stepper': Stepper,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-empty': Empty,
  },
  data () {
    return {
      goodsInfo: {},
      buyInfo: {
        num: 1
      },
      goodsShow: false, // 显示弹窗商品详情
    }
  },
  methods: {
    // 获取商品info done
    getInfo () {
      this.$get({
        url: apis.findCommodity,
        params: {
          commodityId: this.$route.query.commodityId,
          token: this.$global.token
        },
      }).then(rsp => {
        this.goodsInfo = rsp;
      });
    },
    /**跳转提交订单页面 */
    goBuy () {
      if(this.goodsInfo.commodityStock.availableStock>0){
        let obj = {...this.buyInfo,...this.goodsInfo}
        this.buyInfo = obj
        this.$router.push('goodsOrder')
        sessionStorage.setItem('buyInfo',JSON.stringify(this.buyInfo))
      }else{
        return Toast('暂无库存~');
      }
     
    },
    back () {
      this.$router.go(-1)
    },
    /** 显示弹窗商品详情 */
    openInfoPop () {
      this.goodsShow = true
    }
  },
  created () {
    this.getInfo()
  },
  mounted () {
  },
}